.home-page {
    background: #404040 !important;
}
.login-page {
    background-color: #a29bfe !important;
}
.media-size{
    height: 100vh;
    width: 669px;
    margin: 10px 1px;
}