.main-header {
    color: #ffffff;
    text-align: center;
    padding-top: 1rem;
}

.title {
    font-size: 2.5em;
}

.subtitle {
    font-size: 1.5em;
}

.dashboard-stat {
    display: block;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: #2c3e50;
    height: 50px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--text);
    background-size: 100% 100%;
    /* box-shadow: 0 0 0 4px var(--dark) inset; */
    margin: 10px;
    margin-top: 15px;
    padding: 1rem;
}
.logout-btn{
    padding: 10px;
    width: 110px;
    background-color: #3a82f9;
    border-radius: 5px;
}
.logout-btn:hover{
    background-color: #5c96f5;
}
