.audio-player-container {
    border-radius: 10px;
    background-color: #f1f3f4;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.audio-player-control {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

}

.audio-player-control:hover {
    background-color: rgb(221, 221, 221);
    cursor: pointer;
}

.audio-player-control img {
    padding: 10px;
}

.audio-wave {
    padding: 10px 0px;
    margin: 0 10px;
    width: 100%;
}

.audio-time span {
    font-size: 12pt !important;
}

.player-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 5px;
}

.player-buttons-area {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}