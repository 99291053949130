@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Lato', sans-serif;
}

.parent-container {
  /* background: #404040; */
  height: 100vh;
}

.app-title {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  border: 1px solid #0e0e0e;
  margin: 0 auto 10px auto;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 2px rgba(0, 0, 0, 0.23);
  max-width: 400px;
}