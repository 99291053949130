:root {
  --light: #999999;
  --defaultcolor: black;
  --textcolor: white;
  --dark: #555555;
  --text: black;
  --speaker: #311a58;
  --lilac: #8753a8;
}
.disablecolor{
background-color: rgba(0, 0, 0, 0.487);
}
.speaker-unmute {
  background-color: yellow !important;
  
}
.speaker-color {
  background-color: var(--lilac);
  
}
.speaker-btn-color {
background-color: var(--speaker);
}
.wating{
color: white;
font-size: 26px;
animation:blinkingText 1.2s infinite;
}
@keyframes blinkingText{
0%{     color: rgb(255, 255, 255);    }
49%{    color: rgb(255, 255, 255); }
60%{    color: transparent; }
99%{    color:transparent;  }
100%{   color: rgb(255, 255, 255);  }
}
.speed-dial-button {
  display: block;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: var(--defaultcolor);
  width: 300px;
  height: 100px;
  border-radius: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--textcolor);
  background-size: 100% 100%;
  box-shadow: 0 0 0 4px var(--dark) inset;
  margin: 10px;
}
.speaker-buuton {

position: relative;
display: block;
cursor: pointer;
outline: none;
border: none;
width: 300px;
height: 100px;
border-radius: 20px;
font-size: 1.5rem;
font-weight: 600;
color: var(--text);
box-shadow: 0 0 0 4px var(--dark) inset;
margin: 10px;
}
.spearker-icon{
background-image: url('/src/assets/images/spk_channel_icon.png');
background-repeat: no-repeat;
background-size: 80px 80%;
background-position: center;
}
.speaker-number{
position: absolute;
color: #f4f4f4;
   bottom: 10px;
   right: 10px;
   font-size: 14px;
}


.private-call {
background-image: url('/src/assets/images/cancel_image3.jpeg');
background-repeat: no-repeat;
background-size: 100px 100%;
background-position: center;
}

.blink {
animation: blink 1s step-start 0s infinite;
-webkit-animation: blink 1s step-start 0s infinite;
}
/* button:hover {
  background-color: var(--dark);
} */

@keyframes blink {
50% {
  background-color: #c0392b;
}
}
@-webkit-keyframes blink {
50% {
  background-color: #c0392b;
}
}
.blue_blink {
animation: blinker  1s step-start 0s infinite;
-webkit-animation: blinker  1s step-start 0s infinite;
}
/* button:hover {
  background-color: var(--dark);
} */

@keyframes blinker  {
50% {
  background-color: #3C63D3;
}
}
@-webkit-keyframes blinker  {
50% {
  background-color: #3C63D3;
}
}
.main-btn-tab{
width: 900px;
justify-content: flex-end;
}
.volume_up{
background-image: url('/src/assets/images/volume_up_myria.png');
background-color: var(--light);

}
.volume_down{
background-image: url('/src/assets/images/volume_down_myria.png');
background-color: var(--light);

}
.player_play{
background-image: url('/src/assets/images/player_play_myrianet.png');
background-color: rgb(57, 89, 218);

}
.player_stop{
background-image: url('/src/assets/images/player_play_myrianet.png');
background-color: rgb(214, 15, 15);

}
.popuprecoder{
    position: fixed;
      top: 310px;
      left: 30%;
      z-index: 9;
      width: 550px;
}
@media only screen and (min-width: 340px) and (max-width: 389px) {
.popuprecoder {
    position: fixed;
    top: 310px;
    left: 20%;
    border: 4px solid #f1f1f1;
    z-index: 9;
    background: #0000009c;
    height: 164px;
    border-radius: 26px;
    width: 550px;

  }
}
.oppn{
  display:block
}
.closs{
  display: none;
}
.recorwidth{
  width: 100%;
}
.volume-btn{
cursor: pointer;
outline: none;
border: none;
width: 100px;
height: 100px;
border-radius: 20px;
font-size: 1.5rem;
font-weight: 200;
color: var(--text);
background-size: 100% 100%;
margin: 10px 4px;

}
.vlum-flex{
  width: 418px;
}
.group-button{
display: block;
cursor: pointer;
outline: none;
border: none;
background-color: var(--light);
width: 140px;
height: 100px;
border-radius: 20px;
font-size: 1.5rem;
font-weight: 200;
color: var(--text);
background-size: 100% 100%;
margin: 10px;

}
/* .group-button:active{
background:olive;
}
.group-button:focus{
background:rgb(209, 108, 0);
} */
.group-name{
  background: black;
  color: white;
  position: absolute;
  bottom: 10px;
  left: 15px;
  padding: 0px 5px;
}

input[type="range"] { 
margin: auto;
-webkit-appearance: none;
position: relative;
overflow: hidden;
height: 32px;
width: 220px;
cursor: pointer;
border-radius: 0; /* iOS */
margin-top: 15px;
}

::-webkit-slider-runnable-track {
background: rgb(5, 5, 5);
}

/*
* 1. Set to 0 width and remove border for a slider without a thumb
*/
::-webkit-slider-thumb {
-webkit-appearance: none;
width: 20px; /* 1 */
height: 40px;
background: rgb(0, 200, 0);
box-shadow: -100vw 0 0 100vw rgb(0, 253, 0);
border: 2px solid rgb(0, 200, 0);
}

::-moz-range-track {
height: 40px;
background: black;
}

::-moz-range-thumb {
background: rgb(0, 200, 0);
height: 40px;
width: 20px;
border: 3px solid rgb(0, 200, 0);
border-radius: 0 !important;
box-shadow: -100vw 0 0 100vw rgb(0, 253, 0);
/* jjj */
box-sizing: border-box;
}

::-ms-fill-lower { 
background: rgb(3, 128, 3);
}

::-ms-thumb { 
background: rgb(3, 128, 3);
border: 2px solid rgb(3, 128, 3);
height: 40px;
width: 20px;
box-sizing: border-box;
}

::-ms-ticks-after { 
display: none; 
}

::-ms-ticks-before { 
display: none; 
}

::-ms-track { 
background: black;
color: transparent;
height: 40px;
border: none;
}

::-ms-tooltip { 
display: none;
}


.spk-btn-half{
text-align: center;
padding-top: 2%;
width: 100%;
box-sizing: 100%;
background-color: rgba(232, 6, 6, 0);
font-weight: 900;
font-size: 1em;
}
.spk-btn-full{
padding-top: 50%;
width: 100%;
box-sizing: 100%;
background-color: rgba(255, 255, 255, 0);
font-weight: 900;
}

.slider-btn{
padding-left: 15px;
}
.spk-text{
text-align: center;

}
.bar1{
color: red;
width: 218px;
height: 52px;
padding-bottom: 18px;

}
.btnmic1{

display: block;
cursor: pointer;
outline: none;
border: none;
background-color: var(--defaultcolor);
width: 18%;
height: 100px;
border-radius: 20px;
color: var(--textcolor);
font-size: 1.5rem;
font-weight: 600;
background-size: 100% 100%;
box-shadow: 0 0 0 4px var(--dark) inset;
margin: 10px;
}
.modal-backdrop{
opacity: 0 !important;
}

.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  pointer-events: none;
}

#overlay {
  position: fixed;
  display: none;
  z-index: 999;
}